































import { formatRupiah } from "@/apps/core/modules/utils/text";
import { defineComponent } from "@vue/composition-api";
import { toUserDateTimeFormat } from "@/apps/core/modules/utils/datetime";
import router from "@/router";

export default defineComponent({
  name: "AnggotaListBox",
  props: {
    anggota: { type: Object, required: true },
  },
  components: {
    StatusKaryawanTag: () =>
      import("@/apps/nasabah/components/StatusKaryawanTag.vue"),
  },
  setup(props) {
    const goToRiwayat = () =>
      router.push({
        name: "anggota-cicilan",
        params: { id: props.anggota.anggota.id },
      });

    const detailUrl = { name: `${props.anggota.tipeUser.toLowerCase()}-detail`, params: { id: props.anggota.id } };
    return {
      // Data
      detailUrl,

      // Method
      formatRupiah,
      goToRiwayat,
      toUserDateTimeFormat,
    };
  },
});
